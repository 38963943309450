<template>
  <b-container fluid>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="mr-2">
          <b>Name:</b>
          <span class="text-primary">
            <b> {{ product.product_name }}</b></span
          >
        </h4>
        <h4 class="mr-2">
          <b>Date Added:</b>
          <span class="text-primary">
            <b> {{ product.date_added }}</b></span
          >
        </h4>
      </div>
      <div class="d-flex align-items-center">
        <b-button
          variant="primary"
          v-b-modal.record-stock-modal
          :to="{
            name: 'inventory-edit-product',
            params: { id: this.$route.params.id },
          }"
          class="mr-2"
        >
          <feather-icon icon="EditIcon" size="16"></feather-icon>
          Edit
        </b-button>
        <b-button
          variant="dark"
          class="my-1"
          :to="{ path: '/inventory/create' }"
        >
          <feather-icon icon="TrashIcon" size="16" class="mr-25"></feather-icon>
          Delete
        </b-button>
      </div>
    </div>
    <b-row>
      <b-col cols="7">
        <b-card
          title="Product Image"
          style="min-height: 320px; max-height: 400px"
        >
          <div class="d-flex justify-content-center align-items-center">
            <b-carousel
              v-if="product.product_images && product.product_images.length > 0"
              id="carousel-no-animation"
              style="text-shadow: 0px 0px 2px #000; width: 50%; height: auto"
              no-animation
              controls
              indicators
              img-width="360"
              img-height="230"
            >
              <b-carousel-slide
                v-for="(image, index) in product.product_images"
                :key="index"
                :img-src="image.image_path"
                alt="Product Image"
                style="width: 100%; height: 100%"
              ></b-carousel-slide>
            </b-carousel>
            <p v-else>No image available</p>
          </div>
        </b-card>
        <b-card
          title="Basic Information"
          style="min-height: 200px; max-height: 400px"
        >
          <p>
            <span class="font-weight-bold text-primary">Category:</span>
            {{ product.product_category }}
          </p>
          <p>
            <span class="font-weight-bold text-primary"
              >Short Description:</span
            >
            {{ product.short_description }}
          </p>
          <p>
            <span class="font-weight-bold text-primary">Long Description:</span>
            {{ product.long_description }}
          </p>
          <p>
            <span class="font-weight-bold text-primary">Created By:</span>
            {{ product.added_by }}
          </p>
        </b-card>
      </b-col>
      <b-col cols="5">
        <b-card style="min-height: 320px; max-height: 400px">
          <div class="text-center">
            <p class="mb-0 text-primary" style="font-size: 1.25rem">
              <b>Quantity at Hand</b>
            </p>
            <h2 class="text-primary" style="font-size: 2.5rem">
              {{ product.remaining_stock }}
            </h2>
            <p class="mb-0">
              <u>Adjust stock</u>
            </p>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <div>
              <p
                v-for="(wh, index) in product.warehouse"
                :key="index"
                class="mb-0"
              >
                Warehouse {{ wh.warehouse }}
              </p>
            </div>
            <div>
              <p
                v-for="(wh, index) in product.warehouse"
                :key="index"
                class="mb-0"
              >
                {{ wh.stock }}
              </p>
            </div>
          </div>
        </b-card>
        <b-card style="min-height: 200px; max-height: 400px">
          <div class="text-primary" style="font-size: 1.25rem">
            <p class="mb-3"><b>Total Orders</b></p>
          </div>
          <div class="mb-3">
            <p class="mb-0">Purchase</p>
            <h5 class="mb-0">{{ product.cost }}</h5>
          </div>
          <div class="mb-3">
            <p class="mb-0">Sales</p>
            <h5 class="mb-0">{{ product.revenue }}</h5>
          </div>
          <div>
            <p class="mb-0">Profit</p>
            <h5 class="mb-0">{{ product.profit }}</h5>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card
          class="d-flex flex-column justify-content-between"
          style="min-height: 200px; max-height: 400px"
        >
          <div>
            <p class="font-weight-bold text-primary">
              <b>Purchase Information</b>
            </p>
            <p>
              <span class="text-primary">Cost:</span> RM
              {{ product.cost_price }}
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card
          class="d-flex flex-column justify-content-between"
          style="min-height: 200px; max-height: 400px"
        >
          <div>
            <p class="font-weight-bold text-primary">
              <b>Sales Information</b>
            </p>
            <p>
              <span class="text-primary">Margin:</span> {{ product.margin }}%
            </p>
            <p>
              <span class="text-primary">Profit:</span> RM {{ product.profit }}
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class="text-primary">History</h3>
        <!-- <b-button variant="success">
          <feather-icon
            icon="DownloadIcon"
            size="16"
            class="mr-2"
          ></feather-icon>
          Export
        </b-button> -->
      </div>
      <b-form-group>
        <b-form-radio-group
          v-model="transactionType"
          name="transactionType"
          buttons
          class="w-100"
          button-variant="outline-primary"
          @change="handleTransactionTypeChange"
        >
          <b-form-radio value="in" class="w-50" button-variant="primary"
            >In</b-form-radio
          >
          <b-form-radio value="out" class="w-50" button-variant="primary"
            >Out</b-form-radio
          >
        </b-form-radio-group>
      </b-form-group>
      <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
        <template v-slot="{ props }">
          <div
            v-if="props.column.field === 'location'"
            class="d-flex justify-content-center"
          >
            <div class="d-flex flex-column">
              <p class="mb-0">{{ props.row.warehouse.warehouse }}</p>
            </div>
          </div>
        </template>
      </MyTable>
    </b-card>
  </b-container>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FeatherIcon,
  },

  data() {
    return {
      transactionType: "in",
      product: {},
      columns: [
        {
          label: "Order ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Order Date",
          field: "date_adjusted",
          tdClass: "text-center align-middle",
        },
        {
          label: "Location",
          field: "location",
          tdClass: "text-center align-middle",
        },
        {
          label: "Remarks",
          field: "remarks",
          tdClass: "text-center align-middle",
        },
        {
          label: "Quantity",
          field: "quantity",
          tdClass: "text-center align-middle",
        },
        // {
        //   label: "Grand Total",
        //   field: "total_value",
        //   tdClass: "text-center align-middle",
        // },
        // {
        //   label: "Action",
        //   field: "action",
        //   tdClass: "text-center align-middle",
        // },
      ],
      rows: [],
      required,
    };
  },

  created() {
    this.fetchProduct();
  },

  methods: {
    async fetchProduct() {
      this.isLoading = true;
      try {
        const response = await this.$http.get(
          `inventory/admin/products/${this.$route.params.id}`
        );
        this.product = response.data.data;
        this.rows = this.product.product_inventory_adjustments.filter(
          (adjustment) => adjustment.type === "In"
        );
      } catch (error) {
        console.error("Error fetching products:", error);
        this.rows = [];
      } finally {
        this.isLoading = false;
      }
    },
    handleTransactionTypeChange(newValue) {
      if (newValue === "in") {
        this.rows = this.product.product_inventory_adjustments.filter(
          (adjustment) => adjustment.type === "In"
        );
      } else if (newValue === "out") {
        this.rows = this.product.product_inventory_adjustments.filter(
          (adjustment) => adjustment.type === "Out"
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

